import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
// import { Partner, PartnerPage } from '@core/models/interfaces/partners/partners';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PartnersService {
  constructor(private __http: HttpClient) {}

  getAllPartners(): Observable<any[]> {
    return this.__http.get<any>(`${environment.url}clients`).pipe(
      map((res: any) => res.data)
    );
  }
  getPartner(slug: string): Observable<any> {
    return this.__http
      .get<any>(`${environment.url}client-pages?filters[slug][$eq]=${slug}&populate=projectsection.card&populate=logo`)
      .pipe(
        map((res: any) => res.data.length > 0 ? res.data[0] : null)
      );
  }
  
}
